<template>
  <SvgBase iconName="Map" width="24" height="24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.34 3.03L20.5 3C20.78 3 21 3.22 21 3.5V18.62C21 18.85 20.85 19.03 20.64 19.1L15 21L9 18.9L3.66 20.97L3.5 21C3.22 21 3 20.78 3 20.5V5.38C3 5.15 3.15 4.97 3.36 4.9L9 3L15 5.1L20.34 3.03ZM14 6.87L10 5.47V17.13L14 18.53V6.87ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM16 18.55L19 17.54V5.7L16 6.86V18.55Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMap extends Vue {}
</script>
