
import { Options, Vue } from 'vue-class-component'

import BarcodeLabel from '@/components/BarcodeLabel.vue'
import { BARCODE_ACTION_COMPLETE, FETCH_BARCODE, UNDO_TRACKING, UPDATE_ACTIVATION_DATA_BARCODE } from '@/store/actions'
import { getBarcodeLedgerStatus, reconcileBarcode, undolinkingBarcode } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/helpers'
import { IBarcode, TError, IRedirectData, ITracingMessage } from 'smartbarcode-web-core/src/utils/types/index'
import Tracing from 'smartbarcode-web-core/src/lib-components/tracing.vue'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    BarcodeLabel,
    Tracing,
  },
  name: 'TraceView',
})
export default class TraceView extends Vue {
  messages = {} as ITracingMessage
  loading = false as boolean

  created() {
    if (this.$route.params?.barcodeId && this.$route.params?.project) {
      this.currentBarcodeId = this.$route.params?.barcodeId
      this.currentProject = this.$route.params?.project

      // load barcode to store if barcode not load yet
      if (this.barcode.id !== this.currentBarcodeId) {
        this.$store.dispatch(FETCH_BARCODE, { id: this.currentBarcodeId })
      }
    }

    this.messages = {
      trackingNumber: this.trackingNumberName,
      undoTrackingData: this.$t('undo_tracking_data'),
      noTrackingHistory: this.$t('no_tracking_history'),
      packageImages: this.$t('Package Images', { number: '{0}' }),
      confirmUndoTrackingData: this.$t('confirm_undo_tracking_data'),
      cancel: this.$t('cancel'),
      ok: this.$t('ok'),
      trackedDatetime: this.$t('tracked_datetime'),
      trackedUsername: this.$t('tracked_username'),
      sign: this.$t('sign'),
    } as ITracingMessage
  }

  get trackingNumberName(): string {
    return (
      this.project.barcodeTypes[this.barcodeType]?.activationFields?.trackingNumber?.label || this.$t('tracking_number')
    )
  }

  get barcodeType() {
    return this.barcode?.barcodeType
  }

  get project() {
    return this.$store.state.project.details
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode.barcode
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  async invokeConcile(index: number) {
    try {
      const { trackingData } = await reconcileBarcode(this.barcode?.id, index)
      const { isLedgerCertified, lastLedgerCertifiedDateTime, isLedgerRegistered } = trackingData
      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerCertified = isLedgerCertified

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerRegistered = isLedgerRegistered

      this.$refs.tracingComponent.trackingDataItemsLoading[index].lastLedgerCertifiedDateTime =
        lastLedgerCertifiedDateTime
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.$refs.tracingComponent.isLoading = false
    }
  }

  async getLedgerStatus(index: number) {
    try {
      const { trackingData, activationData } = await getBarcodeLedgerStatus(this.barcode?.id, index)
      this.$store.commit(UPDATE_ACTIVATION_DATA_BARCODE, activationData)
      const { isLedgerCertified, lastLedgerCertifiedDateTime, isLedgerRegistered } = trackingData
      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerCertified = isLedgerCertified

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLedgerRegistered = isLedgerRegistered

      this.$refs.tracingComponent.trackingDataItemsLoading[index].isLoading = false

      this.$refs.tracingComponent.trackingDataItemsLoading[index].lastLedgerCertifiedDateTime =
        lastLedgerCertifiedDateTime
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.$refs.tracingComponent.isLoading = false
    }
  }

  redirectTo(data: IRedirectData) {
    const path = data.path
    if (path) {
      this.$router.push({
        name: 'detail',
        params: {
          barcodeId: data.mainBarcodeId,
          project: this.$store.getters.projectParam,
        },
        query: { path },
      })
    } else {
      this.moveTo(data.mainBarcodeId)
    }
  }

  undoTracking(barcodeId: string) {
    this.loading = true
    this.$store
      .dispatch(UNDO_TRACKING, { barcodeId })
      .catch((err: Record<string, Array<string>>) => errorHandler(err))
      .then(() => (this.loading = false))
  }

  @Watch('$store.state.barcode.actionCompleted')
  async onUndoTrackingActionCompleted(action: string) {
    if (action === 'undo_tracking') {
      openMessage(this.$t(`barcode ${action}`), 'success')
      this.$store.dispatch(BARCODE_ACTION_COMPLETE, '')
    }
  }

  unlinkBarcode(barcodeId: string) {
    this.loading = true
    undolinkingBarcode(barcodeId)
      .then(() => {
        this.$message({
          showClose: true,
          message: this.$t('unlinking_success'),
          type: 'success',
        })

        this.moveTo(barcodeId)
      })
      .catch((err) => errorHandler(err))
      .finally(() => (this.loading = false))
  }

  moveTo(barcodeId: string) {
    this.$router.push({
      name: 'detail',
      params: {
        barcodeId: barcodeId,
        project: this.$store.getters.projectParam,
      },
    })
  }
}
