<template>
  <div v-loading.fullscreen="isFullScreenLoading">
    <template v-if="hasTrackingData">
      <div class="tracking-record">
        <div class="flex items-center w-full">
          <div
            class="tracking-record-title flex justify between h-9 items-center text-white rounded-full w-full px-3 py-1"
            :class="'border-2 bg-blue-400'"
          >
            <span class="text-gray-400 w-6 h-6 leading-6 flex justify-center bg-white rounded-full" :class="'bg-white'">
              <div>{{ trackingData.nextTrackPointKey }}</div>
            </span>
            <span class="flex-grow ml-3">
              {{ stepName(trackingData.nextTrackPointKey) }}
            </span>
            <template v-if="hasEsign()">
              <el-button
                type="text"
                class="edit-button text-white"
                style="position: relative"
                size="mini"
                @click="displaySign(trackingData)"
              >
                <IconEdit class="icon" />
              </el-button>
            </template>
            <!-- Fileeee -->
            <template v-if="hasFiles()">
              <el-button
                type="text"
                class="edit-button text-white"
                style="position: relative"
                size="mini"
                @click="displayFiles(trackingData)"
              >
                <IconFile class="icon" />
              </el-button>
            </template>
            <!--  -->
            <template v-if="hasImages()">
              <el-button
                type="text"
                class="edit-button text-white"
                style="position: relative"
                size="mini"
                @click="displayImages(trackingData)"
              >
                <IconImage class="icon" />
              </el-button>
            </template>
            <el-button
              class="text-white"
              size="mini"
              type="text"
              v-if="barcode.isSensorEnabled"
              @click="displaySensor(idx)"
            >
              <IconSensor class="icon pt-1" />
            </el-button>

            <el-popover v-if="trackingData.location" placement="bottom" :width="180" trigger="click">
              <template #reference>
                <el-button
                  type="text"
                  class="edit-button text-white"
                  style="position: relative"
                  size="mini"
                  @click="displayLocation(trackingData)"
                >
                  <IconMap class="icon" />
                </el-button>
              </template>
            </el-popover>
          </div>
        </div>
        <div :class="['step-body ml-5 relative mt-2']">
          <div class="flex mb-3" v-for="data in extractData(trackingData)" :key="data?.label">
            <span class="label-box break-all w-20">{{ data?.label }}</span>
            <div :class="['pl-2 break-words w-full--20 flex items-center text-left']">
              {{ data?.value }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div class="tracing-dialog">
    <ImageViewerDialog v-model:imageDialogVisible="imageDialogVisible" :image="images" />

    <el-dialog
      center
      width="90%"
      top="0"
      :model-value="signDialog"
      :title="eSignCarouselHeaderLabel"
      @closed="signDialog = false"
    >
      <div>
        <el-carousel @change="onESignCarouselChange" arrow="always" :autoplay="false" indicator-position="none">
          <el-carousel-item v-for="(eSign, key) in arrSignImage" :key="key">
            <div style="border: 1px solid #c4c4c4">
              <img :src="eSign.eSign" alt="eSign" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button id="AppDialog_closeDialog" type="primary" @click="signDialog = false">
            {{ $t('ok') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      center
      width="90%"
      top="0"
      :model-value="fileDialog"
      :title="$t('file_urls')"
      @closed="fileDialog = false"
    >
      <div @click="onPreview(value?.url)" class="grid grid-cols-12 mb-2.5" v-for="(value, idx) in arrFile" :key="idx">
        <IconListFile class="col-span-2" />
        <span class="w-full col-span-10 self-center">{{ value?.name }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button id="AppDialog_closeDialog" type="primary" @click="fileDialog = false">
            {{ $t('ok') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import IconMap from '../components/svg/IconMap.vue'
import IconImage from '../components/svg/IconImage.vue'
import IconFile from '../components/svg/IconFile.vue'
import IconEdit from '../components/svg/IconEdit.vue'
import IconArrowBack from '../components/svg/IconArrowBack.vue'
import IconVerticalThreeDots from '../components/svg/IconVerticalThreeDots.vue'
import ImageViewerDialog from 'smartbarcode-web-core/src/components/ImageViewerDialog.vue'
import {
  IBarcode,
  ICustomFieldData,
  IProject,
  ITrackingData,
  ITrackPointForms,
  IFile,
  IRedirectData,
  IBarcodeDefinitionType,
} from 'smartbarcode-web-core/src/utils/types/index'
import { format } from 'date-fns'
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import { cloneDeep, isEmpty } from 'lodash'
import SensorDialog from 'smartbarcode-web-core/src/components/SensorDialog.vue'
import IconSensor from 'smartbarcode-web-core/src/components/svg/IconSensor.vue'
import { BARCODE_ACTIVATION_DATA_KEYS, DATE_TIME, FIELD_TYPE, OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import IconListFile from 'smartbarcode-web-core/src/components/svg/IconListFile.vue'
import { Prop, Emit } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
type TTransformedStructure = Pick<ICustomFieldData, 'label' | 'order'> & {
  [key: string]: { label: string }
}

@Options({
  components: {
    IconMap,
    SensorDialog,
    IconSensor,
    IconImage,
    IconEdit,
    IconVerticalThreeDots,
    IconArrowBack,
    IconFile,
    IconListFile,
    ImageViewerDialog,
  },
  emits: [],
})
export default class Tracing extends Vue {
  @Prop({ type: Object }) readonly project!: IProject
  @Prop({ type: Object }) readonly barcode!: IBarcode
  @Prop({ type: Object, default: {} }) readonly trackingData!: ITrackingData
  @Prop({ type: Object, default: {} }) readonly fileListFormModel!: Record<string, UploadFile[]>

  isLoading = false
  isFullScreenLoading = false
  barcodeDialogVisible = false
  selectedIndex = 0
  imageDialogVisible = false
  images = {}
  signDialog = false
  arrSignImage = [] as {
    label: string
    eSign: string
  }[]

  arrFile = [] as IFile[]
  trackingDataItemsLoading = [] as Array<Record<string, unknown>>
  fileDialog = false
  eSignCarouselIndex = 0
  imageCarouselIndex = 0
  get hasTrackingData() {
    return !isEmpty(this.trackingData)
  }

  get currentBarcodeType() {
    return this.project.barcodeTypes?.[this.barcode.barcodeType]
  }

  currentTrackPointForm(trackingData: ITrackingData): ITrackPointForms {
    const currentTPKey = trackingData?.currentTrackPointKey || ''
    const nextTPKey = trackingData?.nextTrackPointKey || ''
    const newTk = {
      customFields: {},
      location: {},
    } as ITrackPointForms
    return this.project?.trackPoints?.[currentTPKey]?.trackPointForms?.[nextTPKey] || newTk
  }

  getBarcodeTypeInfoByType(barcode: IBarcode): IBarcodeDefinitionType | undefined {
    return barcode.barcodeType ? this.project?.barcodeTypes?.[barcode.barcodeType] : undefined
  }

  stepName(key: string) {
    return this.project.trackPoints?.[key]?.name
  }

  extractFieldLabels(current: string, next: string) {
    const fields: ITrackPointForms | undefined = this.project.trackPoints?.[current]?.trackPointForms?.[next]
    return fields
      ? Object.keys(fields).reduce((acc, cur: string) => {
          const newCur = cur as keyof ITrackPointForms
          if (newCur.match(/^custom[a-zA-Z]*Fields/g)) {
            const temp = {} as Record<string, Pick<ICustomFieldData, 'label' | 'order'>>
            Object.entries(fields[newCur] as Record<string, ICustomFieldData>).forEach(([fieldKey, fieldSetting]) => {
              temp[fieldKey] = {
                label: fieldSetting.label,
                order: fieldSetting.order,
                ...fieldSetting,
              }
            })
            return { ...acc, ...temp }
          } else return acc
        }, {})
      : {}
  }

  extractData(trackingData: ITrackingData) {
    const data: Array<{
      label: string
      value: string
      order?: number
    }> = []
    if (trackingData?.audit?.trackedDateTime) {
      data.push({
        label: 'this.messages.trackedDatetime',
        value: format(new Date(trackingData.audit.trackedDateTime as string), DATE_TIME.WITH_SECOND),
      })
    }

    if (trackingData?.audit?.trackedUserName) {
      data.push({ label: 'this.messages.trackedUsername', value: trackingData.audit.trackedUserName as string })
    }

    if (trackingData.isSkip) {
      return this.extractValueFromArray((data as unknown) as Record<string, ICustomFieldData>[])
    }

    const flattenData = Object.keys(trackingData as ITrackingData).reduce((acc, cur) => {
      if (trackingData.customFields) {
        const newTrackingData = cloneDeep(trackingData.customFields)
        for (const key in newTrackingData) {
          const newValue = {
            value: newTrackingData[key],
            fieldType: newTrackingData[key].fieldType,
            type: BARCODE_ACTIVATION_DATA_KEYS[cur],
          }
          newTrackingData[key] = newValue
        }
        return { ...acc, ...newTrackingData }
      } else {
        return acc
      }
    }, {}) as Record<string, Record<string, string>>

    const flattenFields = this.extractFieldLabels(trackingData.currentTrackPointKey, trackingData.nextTrackPointKey)

    const sortedFields = Object.entries(flattenFields as Record<string, TTransformedStructure>).sort((a, b) => {
      const orderA = a[1].order || 999
      const orderB = b[1].order || 999
      return orderA - orderB
    })

    sortedFields.forEach(([fieldKey, fieldValue]) => {
      if (flattenData[fieldKey]) {
        let value = ''
        const itemFlattenData = flattenData[fieldKey] as Record<string, string>
        value = itemFlattenData.value

        if (!isEmpty(value)) {
          if (Array.isArray(value)) {
            value = value.map((v) => fieldValue[v]?.label || v).join(', ')
          } else if (fieldValue[value]) {
            value = fieldValue[value].label
          }

          data.push({
            value: value,
            label: fieldValue.label || fieldKey,
            order: fieldValue.order,
          })
        }
      }
    })

    for (const key in flattenData) {
      const dataFlattenFields = flattenFields as Record<string, unknown>
      if (!dataFlattenFields[key]) {
        data.push({ value: flattenData[key].value, label: key })
      }
    }

    return this.extractValueFromArray((data as unknown) as Record<string, ICustomFieldData>[])
  }

  extractValueFromArray(data: Record<string, ICustomFieldData>[]) {
    const mappedData = data
      .map((val) => {
        if (val?.value?.fieldType === FIELD_TYPE.TEXT) {
          return { value: val.value.text, label: val.label }
        }
        if (val?.value?.fieldType === FIELD_TYPE.EMAIL) {
          return { value: val.value.email, label: val.label }
        }
        if (val?.value?.fieldType === FIELD_TYPE.PHONE_NUMBER) {
          const phoneNo = val.value.phoneNumber
          if (!phoneNo) return

          const dialCode = OTHER_COUNTRY_LIST[phoneNo.countryCode as keyof typeof OTHER_COUNTRY_LIST].dialCode
          return { value: `${dialCode} ${phoneNo.number}`, label: val.label }
        }
        if (val?.value?.fieldType === FIELD_TYPE.NUMBER) {
          return { value: val.value.number, label: val.label }
        }
        if (val?.value?.fieldType === FIELD_TYPE.DATE) {
          return { value: val.value.date, label: val.label }
        }
        if (val?.value?.fieldType === FIELD_TYPE.SINGLE_SELECT) {
          if (val?.value.singleSelect) {
            return { value: val?.value?.singleSelect.value, label: val.label }
          }
        }
        if (val?.value?.fieldType === FIELD_TYPE.MULTI_SELECT) {
          if (val?.value.multiSelect) {
            const multiSelect = val.value.multiSelect || []
            const value = multiSelect
              .reduce((acc, curr) => {
                return [...acc, curr.value]
              }, [] as string[])
              .join(', ')
            return { value, label: val.label }
          }
        }

        if (val?.value?.fieldType === FIELD_TYPE.REFERENCE) {
          if (val?.value.reference) {
            return { value: val?.value?.reference.text, label: val.label }
          }
        }
        if (val?.value?.fieldType === FIELD_TYPE.GHG_EMISSION && val?.value?.ghgEmission) {
          return { value: val.value.ghgEmission, label: val.label }
        }
        if (val?.value?.fieldType === FIELD_TYPE.CALCULATION && val?.value?.calculation) {
          return { value: val.value.calculation, label: val.label }
        }
        if (val.label && val.value && !val?.value?.fieldType) {
          return val
        }
        console.log(val)
      })
      .filter((val) => !!val)

    return mappedData
  }

  displayLocation(trackingData: ITrackingData) {
    const { location } = trackingData
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`,
      '_blank'
    )
  }

  getValueFromFieldType(data: Record<string, ICustomFieldData>, fieldType: string) {
    try {
      if (!data) return {}
      const filteredData = Object.keys(data).reduce((acc, currKey) => {
        if (data?.[currKey]?.fieldType === fieldType) {
          return { ...acc, [currKey]: data?.[currKey] }
        }
        return acc
      }, {})
      return filteredData || {}
    } catch (e) {
      return {}
    }
  }

  hasEsign() {
    return !isEmpty(this.getValueFromFieldType(this.trackingData?.customFields, FIELD_TYPE.ESIGN))
  }

  hasFiles() {
    const fileObj: Record<string, ICustomFieldData> = this.getValueFromFieldType(
      this.trackingData?.customFields,
      FIELD_TYPE.FILES
    )
    const currentTrackPointCustomField = this.currentTrackPointForm(this.trackingData)?.customFields
    return (
      Object.keys(fileObj)
        .reduce((acc, currKey) => {
          if (
            currentTrackPointCustomField &&
            !['image', 'barcodeScanFrame'].includes(currentTrackPointCustomField?.[currKey]?.fileKind as string)
          ) {
            acc.push(fileObj[currKey])
          }
          return acc
        }, [] as ICustomFieldData[])
        .filter((val) => val.files && val?.files?.length > 0).length > 0 || false
    )
  }

  hasImages() {
    const imagesObj: Record<string, ICustomFieldData> = this.getValueFromFieldType(
      this.trackingData?.customFields,
      FIELD_TYPE.FILES
    )
    const currentTrackPointCustomField = this.currentTrackPointForm(this.trackingData)?.customFields
    return (
      Object.keys(imagesObj)
        .reduce((acc, currKey) => {
          if (
            currentTrackPointCustomField &&
            ['image', 'barcodeScanFrame'].includes(currentTrackPointCustomField?.[currKey]?.fileKind as string)
          ) {
            acc.push(imagesObj[currKey])
          } else {
          }
          return acc
        }, [] as ICustomFieldData[])
        .filter((val) => val.files && val.files.length > 0).length > 0 || false
    )
  }

  displaySign(trackingData: ITrackingData) {
    const eSignObj: Record<string, ICustomFieldData> = this.getValueFromFieldType(
      trackingData?.customFields,
      FIELD_TYPE.ESIGN
    )
    const currentTrackPointCustomField = this.currentTrackPointForm(trackingData)?.customFields || {}
    const eSignImageArr = Object.keys(eSignObj).reduce(
      (acc, currKey) => {
        if (!eSignObj?.[currKey]?.eSign) {
          return acc
        }
        const eSign = {
          label: currentTrackPointCustomField?.[currKey]?.label || '',
          eSign: eSignObj?.[currKey]?.eSign || '',
        }
        acc.push(eSign)
        return acc
      },
      [] as {
        label: string
        eSign: string
      }[]
    )
    this.signDialog = true
    this.arrSignImage = eSignImageArr
  }

  onImageCarouselChange(idx: number) {
    this.imageCarouselIndex = idx
  }

  onESignCarouselChange(idx: number) {
    this.eSignCarouselIndex = idx
  }

  get eSignCarouselHeaderLabel() {
    return this.arrSignImage[this.eSignCarouselIndex]?.label || ''
  }

  displayFiles(trackingData: ITrackingData) {
    const fileObj: Record<string, ICustomFieldData> = this.getValueFromFieldType(
      trackingData?.customFields,
      FIELD_TYPE.FILES
    )
    const currentTrackPointCustomField = this.currentTrackPointForm(trackingData)?.customFields
    const fileArr = Object.keys(fileObj).reduce((acc, currKey) => {
      if (
        currentTrackPointCustomField &&
        !['image', 'barcodeScanFrame'].includes(currentTrackPointCustomField?.[currKey]?.fileKind as string)
      ) {
        return [...acc, ...(((this.fileListFormModel[currKey] as unknown) as IFile[]) || [])]
      }
      return acc
    }, [] as IFile[])
    this.fileDialog = true
    this.arrFile = fileArr
  }

  displayImages(trackingData: ITrackingData) {
    const imageObjSetting: Record<string, ICustomFieldData> = this.getValueFromFieldType(
      trackingData?.customFields,
      FIELD_TYPE.FILES
    )
    const currentTrackPointCustomField = this.currentTrackPointForm(trackingData)?.customFields
    const imageObj = Object.keys(imageObjSetting).reduce((acc, currKey) => {
      if (
        imageObjSetting[currKey] &&
        ['image', 'barcodeScanFrame'].includes(currentTrackPointCustomField?.[currKey]?.fileKind as string)
      ) {
        const getLabelFromKey = currentTrackPointCustomField?.[currKey]?.label
        const files = this.fileListFormModel[currKey] || []
        return { ...acc, [getLabelFromKey || '']: files }
      }

      return acc
    }, {})
    this.images = imageObj
    this.imageDialogVisible = true
  }

  onPreview(url?: string) {
    window.open(url, '_blank')
  }

  @Emit('barcode:unlink')
  unlinkBarcode(barcodeId: string) {
    return barcodeId
  }

  @Emit('web:redirect')
  redirectTo(data: IRedirectData): IRedirectData {
    return data
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

.icon {
  width: 32px;
  height: 32px;
}

.last-confirm-small {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
}

.barcode-id-small {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #979797;
}

.dialog-area :deep() {
  .el-dialog__header {
    padding: 20px;
    text-align: left;
    background-color: #525252;

    .el-dialog__title {
      color: #ffffff;
    }
  }

  .el-dialog__body {
    word-break: normal;
  }

  .el-dialog__headerbtn {
    background-color: #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .barcode-label-container {
    display: flex;
    padding: 5px;
    margin: 0px;

    .action-menu {
      width: auto;
    }
  }
}

.tracking-record {
  &-title {
    .el-button--text {
      color: white;
    }
  }

  .el-button--mini {
    padding: 0px;
  }

  .el-button--mini svg {
    max-width: 22px;
  }

  .arrow-line {
    left: -1px;
    top: calc(100% - 4px);
    transform: translate(-50%, -50%);
  }
}

.border-left {
  border-left: 2px solid #959595;
}

.step-body {
  .label-box {
    font-size: 14px;
    min-width: 50px;
    padding: 2px 8px;
    margin-right: 6px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    line-height: 20px;
    display: inline-block;
    font-weight: 500;
  }
}

.tracking-record:last-child {
  .step-body {
    border-left: 0;
  }
}

.image-dialog {
  &.el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.w-full--20 {
  width: calc(100% - 5rem);
}

.text-gray-400 {
  color: rgb(156, 163, 175);
}

.h-6 {
  height: 1.5rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.bg-blue-400 {
  background-color: rgb(96, 165, 250);
}

.tracing-dialog::v-deep() {
  &.el-dialog {
    background-color: #f2f2f2;
    border-radius: 10px;
    max-height: 90%;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    margin: 0;

    &__header {
      font-weight: bold;
      border-bottom: 1px solid #bdbdbd;
      padding: 25px;
    }

    .el-carousel {
      &__item {
        justify-content: center;
        display: flex;
      }
    }

    .el-image {
      overflow: auto;

      &__inner {
        height: auto;
      }
    }
  }
}

.file-and-value {
  display: flex;
  padding: 8px 0px;

  > div {
    flex-basis: 35%;
    text-align: right;
    margin-right: 8px;
    min-width: 35%;
  }
}
</style>
