
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import ReviewCalculationDialog from '@/components/dialog/ReviewCalculationDialog.vue'
import FormBuilder from '@/components/FormBuilder.vue'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import CustomFieldsMixin from '@/components/mixins/CustomFieldsMixin.vue'
import PreviewTracking from '@/components/PreviewTracking.vue'
import { BARCODE_ACTION_COMPLETE } from '@/store/actions'
import { compileMessage, reviewCalculation, tracking } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { flattenCustomFields, getDynamicLabel, isHideVisibilityConfig } from '@/utils/helpers'
import { isKeyenceEnterpriseBrowser } from '@/utils/keyenceHT/keyenceModules'
import mitt from '@/utils/mitt'
import isEmpty from 'lodash/isEmpty'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums'
import {
  IBarcode,
  IBarcodeDefinitionType,
  ICustomFieldData,
  IFieldSettings,
  ILocation,
  ITrackingRequestBody,
  TError,
  TFieldNotiSettings,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { ProvideReactive, Watch } from 'vue-property-decorator'

@Options({
  components: {
    BarcodeLabel,
    FormBuilder,
    PreviewTracking,
    ReviewCalculationDialog,
  },
  emits: ['submit'],
  name: 'TrackView',
})
export default class TrackView extends mixins(CustomFieldsMixin, BarcodeTypesMixin) {
  @ProvideReactive() isShowArrow = false
  imagesFromOCR: Record<string, string> = {}
  temporaryFieldsOCRImages: Record<string, string[]> = {}
  loading = false
  showDialogAlert = false
  errorDialogMessage = ''
  selectedTarget = ''
  targetChoices = [] as Array<{
    key: string
    name: string
    order?: number
  }>

  location: ILocation | null = null

  isShowCalculationDialog = false
  calculationContent = ''

  get currentBarcodeTypeInfo(): IBarcodeDefinitionType {
    return this.$store.state.project.details.barcodeTypes[this.barcode.barcodeType]
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  get currentTrackPointKey() {
    return this.barcode?.currentTrackPointKey
  }

  get project() {
    return this.$store.state.project.details
  }

  get forms() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.trackPointForms
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  get customForm(): Record<string, IFieldSettings | TFieldNotiSettings> {
    return this.forms && this.forms[this.selectedTarget]
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  get previewAddTrackingVisiblity(): boolean {
    const previewVisibility = this.currentBarcodeTypeInfo?.uiConfig?.previewAddTrackingVisiblity || ''
    return !isHideVisibilityConfig(previewVisibility, this.isAuth, false)
  }

  previewTrackingData = {}
  async loadPreviewTrackingData() {
    const calculationFields = await Object.entries(this.selectedFormSchema)
      .filter((entry) => (entry[1] as IFieldSettings).fieldType === ECustomFieldType.CALCULATION)
      .reduce(
        async (acc, entry) => ({
          ...acc,
          [entry[0]]: {
            fieldType: ECustomFieldType.CALCULATION,
            calculation: await this.getReviewCalculation(entry[0]),
          },
        }),
        {}
      )

    this.previewTrackingData = {
      ...this.generateFormRequest.trackingData,
      customFields: {
        ...this.generateFormRequest.trackingData.customFields,
        ...calculationFields,
      },
    }
  }

  async getReviewCalculation(calculationFieldKey: string) {
    this.appendOCRImage()
    const reviewPayload = {
      field: 'trackingData',
      customFieldKey: calculationFieldKey,
      startTrackPointKey: this.currentTrackPointKey,
      endTrackPointKey: this.selectedTarget,
      barcode: {
        ...this.barcode,
        trackingData: [this.generateFormRequest.trackingData],
      },
    }

    let content = ''
    try {
      content = (await reviewCalculation(reviewPayload)).data
    } catch (e) {
      errorHandler(e as TError)
    }
    return content
  }

  async reviewCalculation(calculationFieldKey: string) {
    this.loading = true
    this.calculationContent = await this.getReviewCalculation(calculationFieldKey)
    this.isShowCalculationDialog = true
    this.loading = false
  }

  labelOfBC(bc: IBarcode): string | undefined {
    const customValues = bc?.activationData || {}
    const customFields = this.currentBarcodeTypeInfo?.activationFields || {}
    const pathKey = this.currentBarcodeTypeInfo?.uiConfig.listViewTitleLabelField || ''
    const flattenCustomValues = flattenCustomFields(
      (customValues as unknown) as Record<string, Record<string, unknown>>
    ) as Record<string, ICustomFieldData>
    const flattenFields = flattenCustomFields(
      (customFields as unknown) as Record<string, Record<string, unknown>>
    ) as Record<string, IFieldSettings>
    return getDynamicLabel(flattenFields, flattenCustomValues, pathKey)
  }

  get selectedStep(): string {
    return this.$store.state.project.details.trackPoints[this.selectedTarget]?.name
  }

  get allowSkipTracking() {
    return this.forms[this.selectedTarget].allowSkipTracking
  }

  get hasRemarks(): boolean {
    return !isEmpty(this.customHtml)
  }

  get customHtml(): string {
    return (
      this.barcodeType?.trackPointRouteRemarks?.find(
        (remarks) => remarks.from === this.currentTrackPointKey && remarks.to === this.selectedTarget
      )?.remarks ?? ''
    )
  }

  mergedHtml = ''
  @Watch('customHtml')
  onUpdateCustomHtml() {
    if (!this.hasRemarks) return

    const cleanedHTML = this.customHtml.replace(/(\r\n|\n|\r)/gm, '')
    if (!isEmpty(cleanedHTML)) {
      compileMessage(this.$route.params.barcodeId, this.customHtml)
        .then((res) => (this.mergedHtml = res))
        .catch((err) => errorHandler(err))
    }
  }

  get barcodeType(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes?.[this.barcode?.barcodeType]
  }

  get barcodeName(): string {
    return this.barcodeType.name || ''
  }

  get isAddTrackingDialog() {
    return this.dialogMode === 'add-tracking'
  }

  dialogMode = 'add-tracking'
  async confirmSubmitDialog() {
    if (!this.showDialogAlert) {
      this.dialogMode = 'add-tracking'
      this.DialogMessage = 'confirm_add_track_data'
      await this.loadPreviewTrackingData()
      this.showDialogAlert = true
    }
  }

  confirmSkipTrackingDialog() {
    if (!this.showDialogAlert) {
      this.dialogMode = 'skip-add-tracking'
      this.DialogMessage = 'confirm_skip_track_data'
      this.showDialogAlert = true
    }
  }

  get isAllowBulkUpdateAtCurrentTrackPoint() {
    return this.currentBarcodeTypeInfo?.bulkUpdatableTrackPoints.includes(this.currentTrackPointKey)
  }

  onRouteTo(view: string, id?: string) {
    this.showDialogAlert = false
    const nextSelectedTrackingPoint: string =
      (this.targetChoices.find((val) => val.key === this.selectedTarget)?.name as string) || ''

    localStorage.tempRequestForMultipleBarcodeScan = JSON.stringify(this.generateFormRequest)

    this.$router.replace({
      name: view,
      params: {
        project: this.$store.getters.projectParam,
        barcodeId: id || this.barcode?.id,
        nextTrackName: nextSelectedTrackingPoint || '',
      },
    })
  }

  async submit(isSkip: boolean) {
    try {
      this.showDialogAlert = false
      this.loading = true
      const request = {
        ...this.generateFormRequest,
        selectedNextTrackpoint: this.nextSelectTrackingPointName,
        isSkip,
        isDryRun: false,
      }
      await tracking(request)
      this.imagesFromOCR = {}
      this.temporaryFieldsOCRImages = {}
      this.$store.dispatch(BARCODE_ACTION_COMPLETE, 'tracked')
      this.$router.push({
        name: 'detail',
        params: { barcodeId: this.barcode.id },
      })
    } catch (error) {
      const modelObject = {
        barcodeType: this.barcodeName,
        fTP1: this.currentTrackPointName,
        tTP2: this.targetChoices.find((val) => val.key === this.selectedTarget)?.name || '',
      }

      errorHandler(error as TError, modelObject)
    } finally {
      this.loading = false
    }
  }

  get generateFormRequest() {
    this.appendOCRImage()
    const generatedCustomDataForRequest = this.generateCustomDataForRequest
    const { location, ...other } = generatedCustomDataForRequest
    const requestBody: ITrackingRequestBody = {
      barcodeIds: [this.barcode.id],
      trackingData: {
        currentTrackPointKey: this.barcode.currentTrackPointKey,
        nextTrackPointKey: this.selectedTarget,
        location,
        customFields: {
          ...other,
        },
      },
    }

    return requestBody
  }

  @Watch('selectedTarget')
  updateCustomFormModel() {
    this.formModel = {}
    this.generateCustomFormModel(undefined)
  }

  appendOCRImage() {
    if (this.imagesFromOCR) {
      Object.keys(this.imagesFromOCR).forEach((key) => {
        this.formModel[key] = [...this.formModel?.[key], ...this.imagesFromOCR?.[key]]
      })
    }
  }

  mounted() {
    const project = this.$store.state.project.details
    if (this.forms) {
      this.targetChoices = Object.keys(this.forms).map((stepKey) => ({
        key: stepKey,
        name: project.trackPoints[stepKey].name,
        order: this.forms[stepKey].order,
      }))

      if (this.targetChoices.length > 0) {
        this.targetChoices.sort((a, b) => (a.order && b.order && a.order - b.order) || 0)
        this.selectedTarget = this.targetChoices[0].key
      }

      this.generateCustomFormModel(undefined)
    }

    mitt.on('update:trackInfo', (event) => {
      this.formModel = Object.assign(this.formModel, event)
    })

    mitt.on('update:fileList', (event) => {
      this.fileListFormModel = Object.assign(this.fileListFormModel, event)
    })

    mitt.on('update:imageOCR', (event) => this.saveOCRImage(event))
  }

  get isKeyenceEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }
}
