<template>
  <SvgBase iconName="VerticalThreeDots" width="34" height="34" viewBox="0 0 34 34">
    <g id="surface1">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M 19.125 17 C 19.125 18.171875 18.171875 19.125 17 19.125 C 15.828125 19.125 14.875 18.171875 14.875 17 C 14.875 15.828125 15.828125 14.875 17 14.875 C 18.171875 14.875 19.125 15.828125 19.125 17 Z M 19.125 17 "
        :fill="color"/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M 19.125 24.359375 C 19.125 25.53125 18.171875 26.484375 17 26.484375 C 15.828125 26.484375 14.875 25.53125 14.875 24.359375 C 14.875 23.183594 15.828125 22.234375 17 22.234375 C 18.171875 22.234375 19.125 23.183594 19.125 24.359375 Z M 19.125 24.359375 "
        :fill="color"/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M 19.125 9.640625 C 19.125 10.816406 18.171875 11.765625 17 11.765625 C 15.828125 11.765625 14.875 10.816406 14.875 9.640625 C 14.875 8.46875 15.828125 7.515625 17 7.515625 C 18.171875 7.515625 19.125 8.46875 19.125 9.640625 Z M 19.125 9.640625 "
        :fill="color"/>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M 32.664062 10.386719 C 31.371094 7.332031 29.21875 4.742188 26.507812 2.910156 C 23.792969 1.070312 20.511719 0 17 0 C 14.65625 0 12.417969 0.476562 10.386719 1.335938 C 7.332031 2.628906 4.742188 4.78125 2.910156 7.492188 C 1.070312 10.207031 0 13.488281 0 17 C 0 19.34375 0.476562 21.582031 1.335938 23.613281 C 2.628906 26.667969 4.78125 29.257812 7.492188 31.089844 C 10.207031 32.929688 13.488281 34 17 34 C 19.34375 34 21.582031 33.523438 23.613281 32.664062 C 26.667969 31.371094 29.257812 29.21875 31.089844 26.507812 C 32.929688 23.792969 34 20.511719 34 17 C 34 14.65625 33.523438 12.417969 32.664062 10.386719 Z M 31.382812 17 C 31.382812 18.984375 30.976562 20.878906 30.253906 22.597656 C 29.164062 25.175781 27.339844 27.378906 25.042969 28.925781 C 23.894531 29.703125 22.632812 30.3125 21.277344 30.738281 C 19.929688 31.15625 18.496094 31.382812 17 31.382812 C 15.015625 31.382812 13.121094 30.976562 11.402344 30.253906 C 8.824219 29.164062 6.621094 27.339844 5.074219 25.042969 C 4.296875 23.894531 3.683594 22.632812 3.261719 21.277344 C 2.84375 19.929688 2.617188 18.496094 2.617188 17 C 2.617188 15.015625 3.019531 13.121094 3.746094 11.402344 C 4.835938 8.824219 6.660156 6.621094 8.957031 5.074219 C 10.105469 4.296875 11.367188 3.683594 12.722656 3.261719 C 14.070312 2.84375 15.503906 2.617188 17 2.617188 C 18.984375 2.617188 20.878906 3.019531 22.597656 3.746094 C 25.175781 4.835938 27.378906 6.660156 28.925781 8.957031 C 29.703125 10.105469 30.3125 11.367188 30.738281 12.722656 C 31.15625 14.070312 31.382812 15.507812 31.382812 17 L 32.691406 17 Z M 31.382812 17 "
        :fill="color"/>
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconVerticalThreeDots extends Vue {}
</script>
